import React, {Component} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Link} from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import bakeryLogo from '../../assets/images/general/stevers-bakery-logo-1.svg'
import assortmentImage from '../../assets/images/general/home-page-assortment.png'
import webshopImage from '../../assets/images/general/webshop.png'
import HomePageCard from '../../components/HomePageCard'


class HomePage extends Component {

  componentDidMount() {
  }

    render() {

        const {showWebShop, handlePress} = this.props

        return (<Container>
            <Row className="justify-content-center">
                <Col>
                </Col>
                <Col className={'col-3'}>
                    <Image src={bakeryLogo} rounded style={{width: '100%'}} onClick={() => handlePress()}/>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center">
                <Col className='col-6'>
                    <Link to={'/assortiment'} style={{textDecoration: 'none'}}>
                        <HomePageCard imageUrl={assortmentImage} name={'Assortiment'}/>
                    </Link>
                </Col>
                {showWebShop && <Col className='col-6'>
                    <Link to={'/webshop'} style={{textDecoration: 'none'}}>
                        <HomePageCard imageUrl={webshopImage} name={'Webshop'}/>
                    </Link>
                </Col>}

            </Row>
        </Container>)
    }

}


export default HomePage
