import React, {Component} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom"
import HomePage from './pages/HomePage'
import Assortiment from './pages/Assortment'
import Webshop from './pages/Webshop'

const initialState = {
    pressCount:  0,
    showWebShop: true,
}

class App extends Component {

    state = initialState

    handlePress = () => {
        this.setState({pressCount: this.state.pressCount + 1}, () => {
            if (this.state.pressCount === 5) {
                this.setState({showWebShop: !this.state.showWebShop, pressCount: 0})
            }
        })
    }

    render() {

        return (
            <Router>
                <Switch>
                    <Route path="/" exact={true} render={props => <HomePage  {...props} showWebShop={this.state.showWebShop} handlePress={this.handlePress}/>}/>
                    <Route path="/assortiment" component={Assortiment} exact={true}/>
                    <Route path="/webshop" component={Webshop} exact={true}/>
                    <Route path='/assortiment/:assortmentType' component={Assortiment} exact={true}/>
                    <Route path='/assortiment/:assortmentType/:assortmentSubType' component={Assortiment} exact={true}/>
                    <Route path="*" render={() => <Redirect to={'/'}/>}/>
                </Switch>
            </Router>
        )
    }
}


export default App
