import React from 'react'
import Iframe from 'react-iframe'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HomeButton from '../../components/HomeButton'
import './index.css'

const Webshop = ({history}) => {

    return (<Container style={{height: '100vh', padding: 50}} fluid>
        <Row style={{height: '100%'}}>
            <Col>
                <HomeButton history={history} stylesAlt={{position: 'absolute', zIndex: 999, top: -20}}/>
                <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                    <Iframe url={'https://www.steversbanket.nl/webshop'}
                            height={'100%'}
                            width={'100%'}
                            display="initial"
                            className={'frame'}
                            position="relative"/>
                </div>
            </Col>
        </Row>

    </Container>)
}

export default Webshop
