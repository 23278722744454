import React from 'react'
import Modal from 'react-bootstrap/Modal'
import styles from './styles'
import {css} from 'aphrodite'
import CloseButton from '../../components/CloseButton'

const ImageModal = (props) => {

    const {imageUrl} = props

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <CloseButton onHide={props.onHide}/>
            <Modal.Body style={{height: '80vh'}}>
                <div className={css(styles.image)} style={{backgroundImage: `url("${imageUrl}")`}}/>
            </Modal.Body>

        </Modal>
    )
}

export default ImageModal
