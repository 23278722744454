import {StyleSheet} from 'aphrodite'
import theme from '../../utils/theme'

export default StyleSheet.create({
    closeButton: {
        position:        'absolute',
        top:             -25,
        right:           -25,
        zIndex:          999,
        borderRadius:    '50%',
        border:          'transparent',
        backgroundColor: theme.colors.purple,
        height:          50,
        width:           50,
    },

})
