import React from 'react'
import Col from 'react-bootstrap/Col'
import CakeCard from '../CakeCard'

const CakeCardRow = ({assortmentRow, toggleShowProductImage, history, selectedImage}) => {

    return <React.Fragment>
        <Col className='col-3 p-2 m-0'>
            {assortmentRow[0] &&
            <CakeCard
                selectedImage={selectedImage}
                toggleShowProductImage={toggleShowProductImage}
                cake={assortmentRow[0]}
                name={assortmentRow[0].name}
                navUri={assortmentRow[0].navUri}
                imageUrl={assortmentRow[0].imageUrl}
                history={history}/>}
        </Col>
        <Col className='col-3 p-2 m-0'>
            {assortmentRow[1] &&
            <CakeCard
                selectedImage={selectedImage}

                toggleShowProductImage={toggleShowProductImage}
                cake={assortmentRow[1]}
                name={assortmentRow[1].name}
                navUri={assortmentRow[1].navUri}
                imageUrl={assortmentRow[1].imageUrl}
                history={history}/>}</Col>
        <Col className='col-3 p-2 m-0'>
            {assortmentRow[2] &&

            <CakeCard
                selectedImage={selectedImage}

                toggleShowProductImage={toggleShowProductImage}
                cake={assortmentRow[2]}
                name={assortmentRow[2].name}
                navUri={assortmentRow[2].navUri}
                imageUrl={assortmentRow[2].imageUrl}
                history={history}/>}</Col>
        <Col className='col-3 p-2 m-0'>
            {assortmentRow[3] &&
            <CakeCard
                selectedImage={selectedImage}

                toggleShowProductImage={toggleShowProductImage}
                cake={assortmentRow[3]}
                name={assortmentRow[3].name}
                navUri={assortmentRow[3].navUri}
                imageUrl={assortmentRow[3].imageUrl}
                history={history}/>}</Col>
    </React.Fragment>
}

export default CakeCardRow
