import {StyleSheet} from 'aphrodite'
import theme from '../../utils/theme'

export default StyleSheet.create({
    card:           {
        height: 375,
        display:       'flex',
        flexDirection: 'column',
        minWidth: 0,
        boxShadow:     '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition:    '0.3s',
        borderRadius:  5,
        backgroundColor: theme.colors.green,
        ":hover":      {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
        },
    },
    imageContainer: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
    },
    img:            {
        verticalAlign: 'middle',
        width:                       '100%',
        padding:                     8,

    },

    textContainer: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        padding:        '2.5rem',
        textAlign:      'center',
        width:          '100%',
        height:         '100%',
        overflow:       'hidden',

    },
    link:          {
        fontSize: 35,
        color:    theme.colors.white,
    },


})
