import React from 'react'
import {css} from 'aphrodite/no-important'
import s from './styles'
import Image from 'react-bootstrap/Image'
import forwardsIcon from '../../assets/images/icons/forwards-icon.svg'
import backwardsIcon from '../../assets/images/icons/backwards-icon.svg'


const CarouselButton =  ({direction, handleDirection}) => {
    const styles = s(direction)

    const directionIcon = direction === 'forwards' ? forwardsIcon : direction === 'backwards' ? backwardsIcon : ''

    return <button className={[css(styles.carouselBtn)]}
        onClick={() => handleDirection(direction)}
    >
        <Image  src={directionIcon} fluid/>
    </button>
}

export default CarouselButton

