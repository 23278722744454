import {StyleSheet} from 'aphrodite'
import theme from '../../utils/theme'

const s = (item) => {

    const currentLevelIsActive = !!(item.children.length === 0)

    return StyleSheet.create({
        link: {
            textDecoration: currentLevelIsActive ? 'none' : 'underline',
            color:          currentLevelIsActive ? theme.colors.purple : theme.colors.black,
            display:        'inline-block',
            marginRight:    5,
            marginLeft:     5,
            fontSize:       20,
            marginBottom:   5,
        },
    })
}

export default s
