import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import Row from 'react-bootstrap/Row'
import CakeCardRow from '../CakeCardRow'

const divideAssortment = (assortment, chunkSize) => {

    if (!assortment) return []
    const dividedAssortment = []


    for (let i = 0; i < assortment.length; i += chunkSize) {
        dividedAssortment.push(assortment.slice(i, i + chunkSize))
    }

    return dividedAssortment
}

const checkDirection = (index, lastIndex) => {
    return lastIndex > index ? 'backwards' : 'forwards'
}

const renderSwipableViews = ({dividedAssortment, toggleShowProductImage, history, selectedImage}) => {

    return dividedAssortment.map((assortmentToShow, idx) => {
        const forSwipeAssortment = divideAssortment(assortmentToShow, 4)

        const firstRow = forSwipeAssortment[0]
        const secondRow = forSwipeAssortment[1]


        return (
            <React.Fragment key={idx}>
                <div style={{paddingLeft: 100, paddingRight: 100}}>
                    <Row>
                        <CakeCardRow assortmentRow={firstRow} toggleShowProductImage={toggleShowProductImage}
                                     history={history} selectedImage={selectedImage}/>
                    </Row>
                </div>
                {secondRow &&
                <div style={{paddingLeft: 100, paddingRight: 100}}>
                    <Row>
                        <CakeCardRow assortmentRow={secondRow} toggleShowProductImage={toggleShowProductImage}
                                     history={history} selectedImage={selectedImage}/>
                    </Row>
                </div>}
            </React.Fragment>
        )
    })


}

const Swipeable = ({toggleShowProductImage, history, dividedAssortment, selectedAssortmentIndex, handleDirection, selectedImage}) => {


    return <SwipeableViews
        index={selectedAssortmentIndex}
        onChangeIndex={(index, indexLatest) => {
            handleDirection(checkDirection(index, indexLatest))
        }}
    >
        {renderSwipableViews({dividedAssortment, toggleShowProductImage, history, selectedImage})}
    </SwipeableViews>
}


export default Swipeable
