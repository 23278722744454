import React from 'react'
import styles from './styles'
import {css} from 'aphrodite'
import Image from 'react-bootstrap/Image'

import closeIcon from '../../assets/images/icons/close-icon.svg'

const CloseButton = ({onHide}) => {
    return <button className={[css(styles.closeButton)]} onClick={onHide}>
        <Image src={closeIcon} fluid/>
    </button>
}

export default CloseButton
