import React from 'react'
import styles from './styles'
import {css} from 'aphrodite/no-important'


const HomePageCard = ({imageUrl, name}) => {


    return (

        <div className={[css(styles.card)]}>
            {/*<div className={[css(styles.imageContainer)]}>*/}
            {/*    <img src={imageUrl} alt={name} className={[css(styles.img)]}/>*/}
            {/*</div>*/}
            <div className={[css(styles.textContainer)]}>
                <h4 className={[css(styles.link)]}><b>{name}</b></h4>
            </div>
        </div>)
}

export default HomePageCard
