import {StyleSheet} from 'aphrodite'
import theme from '../../utils/theme'


export default StyleSheet.create({
    categoryCard: {
        display:       'flex',
        alignItems:    'center',
        flexDirection: 'column',
        boxShadow:     '0 2px 4px 0 rgba(0,0,0,0.2)',
        transition:    '0.3s',
        borderRadius:  5,
        // ":hover":      {
        //     boxShadow: '0 4px 8px 4px rgba(141,67,115,0.6)',
        // },
        backgroundColor: theme.colors.green,
        height: 175,
    },

    cardSelected: {
        boxShadow: '0 4px 8px 4px rgba(141,67,115,0.6)',
    },

    card:           {
        display:       'flex',
        alignItems:    'center',
        flexDirection: 'column',
        boxShadow:     '0 2px 4px 0 rgba(0,0,0,0.2)',
        transition:    '0.3s',
        borderRadius:  5,
        // ":hover":      {
        //     boxShadow: '0 4px 8px 4px rgba(141,67,115,0.6)',
        // },
        backgroundColor: theme.colors.white,
        height: 175,
    },
    imageContainer: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        width: '100%',
        height: 300,
        padding: 4
    },
    image: {
        borderRadius: 4,
        height: '100%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },

    categoryTextContainer: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        textAlign:      'center',
        width:          '100%',
        height:         '100%',
        overflow:       'hidden',
        paddingTop: 10
    },

    textContainer: {
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
        textAlign:      'center',
        width:          '100%',
        height:         '50%',
        overflow:       'hidden',
        paddingTop: 10
    },

    categoryLink: {
        fontSize: 20,
        padding: 5,
        color:    theme.colors.white,
    },

    link:          {
        fontSize: 14,
        color:    theme.colors.purple,
    },


})
