import {StyleSheet} from 'aphrodite'


export default StyleSheet.create({

    image: {
        height:             '100%',
        width:              '100%',
        backgroundPosition: 'center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:     '100%',
    },
})
