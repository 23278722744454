import React from 'react'
import styles from './styles'
import {css} from 'aphrodite/no-important'


const CakeCard = ({imageUrl, name, navUri, history, cake, toggleShowProductImage, selectedImage}) => {

    const cakeType = (cake && cake.type) ? cake.type : ''
    const isSelected = selectedImage === name
    const isProduct = cakeType === 'product'

    if (isProduct) {

        return (<div className={[css(styles.card), isSelected ? css(styles.cardSelected) : ''].join(' ')}
                     onClick={() => {

                         toggleShowProductImage(imageUrl, name)

                     }}>
            <div className={[css(styles.imageContainer)]}>
                <div className={css(styles.image)} style={{backgroundImage: `url("${imageUrl}")`}}/>
            </div>

            <div className={[css(styles.textContainer)]}>
                <h4 className={[css(styles.link)]}>{name}</h4>
            </div>

        </div>)
    } else {
        return (

            <div className={[css(styles.categoryCard)]}
                 onClick={() => {
                     history.push(`/assortiment/${navUri}`)
                 }}>
                <div className={[css(styles.categoryTextContainer)]}>
                    <h4 className={[css(styles.categoryLink)]}>{name}</h4>
                </div>

            </div>)
    }


}

export default CakeCard
