const colors = {
    purple: '#8D4373',
    green: '#768775',
    white: '#FFF',
    black: '#000'
}



const theme = {
    colors,
    card: {
        position: 'relative',
        display:       'flex',
        flexDirection: 'column',
        minWidth: 0,
        boxShadow:     '0 2px 4px 0 rgba(0,0,0,0.2)',
        transition:    '0.3s',
        borderRadius:  5,
        backgroundColor: colors.white,
        ":hover":      {
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        },
    }
}

export default theme
