import {StyleSheet} from 'aphrodite'
import theme from '../../utils/theme'


const s = (direction) => {


    return StyleSheet.create({
        carouselBtn: {
            position:                'fixed',
            top:                     '45%',
            right:                   direction === 'forwards' ? 0 : 'inherit',
            left:                    direction === 'backwards' ? 0 : 'inherit',
            height:                  '10%',
            color:                   theme.colors.white,
            backgroundColor:         theme.colors.purple,
            border:                  'transparent',
            borderTopLeftRadius:     direction === 'forwards' ? 5 : 'inherit',
            borderTopRightRadius:    direction === 'backwards' ? 5 : 'inherit',
            borderBottomLeftRadius:  direction === 'forwards' ? 5 : 'inherit',
            borderBottomRightRadius: direction === 'backwards' ? 5 : 'inherit',
            width:                   '3%',
            padding: 0,
            zIndex: 999
        },
    })
}


export default s

