import React, {Component} from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import CarouselButton from '../../components/CarouselButton'
import HomeButton from '../../components/HomeButton'
import bakeryLogo from '../../assets/images/general/stevers-bakery-logo-1.svg'
import BreadCrumb from '../../components/BreadCrumb'
import ImageModal from '../../components/ImageModal'
import request from 'superagent'
import Swipeable from '../../components/Swipeable'
import {IMAGE_API_URL} from '../../application-context'

const initialState = {
    selectedAssortmentIndex:  0,
    dividedAssortment:        [],
    currentAssortmentType:    '',
    currentAssortmentSubType: '',
    currentType:              '',
    breadCrumbTree:           '',
    numberOfPages:            '',
    currentCakeOnDisplayUrl:  '',
    showProductImage:         false,
    selectedImage: ''
}


class Assortment extends Component {

    state = initialState

    async componentDidMount() {

        const cakeData = await request('GET', IMAGE_API_URL, {

        }).then(res => {
            return res.body
        }).catch(err => console.log('err', err))

        const location = this.props.location.pathname
        const breadCrumbTree = this.createBreadCrumb(location)
        const dividedAssortment = this.divideAssortment(cakeData ? cakeData.default : [], 8)
        const root = Object.entries(this.props.match.params).length === 0 && this.props.match.params.constructor === Object
        const numberOfPages = dividedAssortment.length


        this.setState({
            completeAssortment: cakeData ? cakeData : [],
            breadCrumbTree,
            dividedAssortment,
            currentType:        root ? 'default' : location,
            numberOfPages,
        })
    }

    componentDidUpdate(prevProps, prevState) {


        const assortmentType = this.props.match.params.assortmentType
        const assortmentSubType = this.props.match.params.assortmentSubType
        const currentType = assortmentSubType ? assortmentSubType : assortmentType ? assortmentType : 'default'
        const location = this.props.location.pathname
        const breadCrumbTree = this.createBreadCrumb(location)

        // Return if the assortment type equels the set assortment type
        if (currentType === this.state.currentType) return


        const dividedAssortment = this.divideAssortment(this.state.completeAssortment[currentType], 8)


        const numberOfPages = dividedAssortment.length

        this.setState({
            selectedAssortmentIndex: initialState.selectedAssortmentIndex,
            numberOfPages,
            breadCrumbTree,
            dividedAssortment,
            currentAssortmentType:    assortmentType,
            currentAssortmentSubType: assortmentSubType,
            currentType,
        })


    }

    listToTree = (list) => {

        let map = {}, node, roots = [], i
        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i // initialize the map
            list[i].children = [] // initialize the children
        }
        for (i = 0; i < list.length; i += 1) {
            node = list[i]
            if (node.parentId !== "0") {
                // if you have dangling branches check that map[node.parentId] exists
                list[map[node.parentId]].children.push(node)
            } else {
                roots.push(node)
            }
        }
        return roots
    }


    createBreadCrumb = location => {

        const breadCrumb = location.split('/').slice(1)

        const list = breadCrumb.map((item, idx) => {
            return {
                "id":       (idx + 1).toString(),
                "name":     item,
                "level":    idx.toString(),
                "parentId": idx.toString(),
                "url":      breadCrumb.slice(0, (idx + 1)).join("/"),
                "children": null,
            }
        })

        return this.listToTree(list)

    }

    divideAssortment = (assortment, chunkSize) => {


        if (!assortment) return []
        const dividedAssortment = []


        for (let i = 0; i < assortment.length; i += chunkSize) {
            dividedAssortment.push(assortment.slice(i, i + chunkSize))
        }

        return dividedAssortment
    }


    handleDirection = direction => {
        this.setState({
            selectedAssortmentIndex: direction === 'forwards' ? this.state.selectedAssortmentIndex + 1 : direction === 'backwards' ? this.state.selectedAssortmentIndex - 1 : this.state.selectedAssortmentIndex,
        })
    }

    directionIsValid = {
        assortmentIndexForwards:  () => !!(this.state.dividedAssortment[this.state.selectedAssortmentIndex + 1]),
        assortmentIndexBackwards: () => !!(this.state.dividedAssortment[this.state.selectedAssortmentIndex - 1]),

    }

    toggleShowProductImage = (imageUrl, selectedImage) => {
        this.setState({showProductImage: !this.state.showProductImage, currentCakeOnDisplayUrl: imageUrl, selectedImage})
    }

    resetSelectedAssortmentIndex = () => {
        this.setState(({selectedAssortmentIndex: initialState.selectedAssortmentIndex}))
    }


    render() {


        const {dividedAssortment, selectedAssortmentIndex, breadCrumbTree, numberOfPages, showProductImage, currentCakeOnDisplayUrl, selectedImage} = this.state
        const {history} = this.props
        const assortment = dividedAssortment[selectedAssortmentIndex]
        const forwardsIsValid = this.directionIsValid.assortmentIndexForwards()
        const backwardsIsValid = this.directionIsValid.assortmentIndexBackwards()

        return (
            <Container fluid>
                {backwardsIsValid && <CarouselButton direction={'backwards'} handleDirection={this.handleDirection}/>}
                {forwardsIsValid && <CarouselButton direction={'forwards'} handleDirection={this.handleDirection}/>}

                <Container fluid style={{paddingLeft: 50, paddingRight: 50}}>
                    <Row className={'align-items-center'}>
                        <Col className='col-10'>
                            <HomeButton history={history}/>
                        </Col>

                        <Col className='col-2'>
                            <Image src={bakeryLogo} rounded style={{height: 65}} fluid/>

                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='col-11'>
                            {breadCrumbTree &&
                            <BreadCrumb resetSelectedAssortmentIndex={this.resetSelectedAssortmentIndex}
                                        breadCrumb={breadCrumbTree} history={history}/>}
                        </Col>
                        <Col className='col-1'>
                            {`${selectedAssortmentIndex + 1} / ${numberOfPages}`}
                        </Col>
                    </Row>
                </Container>

                {assortment && <Swipeable
                    selectedImage={selectedImage}
                    handleDirection={this.handleDirection}
                    assortment={assortment}
                    selectedAssortmentIndex={selectedAssortmentIndex}
                    dividedAssortment={dividedAssortment}
                    toggleShowProductImage={this.toggleShowProductImage}
                    history={history}/>}


                <ImageModal show={showProductImage} onHide={this.toggleShowProductImage}
                            imageUrl={currentCakeOnDisplayUrl}/>
            </Container>
        )
    }
}

export default Assortment
