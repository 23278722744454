import React from 'react'
import styles from './styles'
import {css} from 'aphrodite/no-important'
import Image from 'react-bootstrap/Image'
import homeLogo from '../../assets/images/icons/home-icon.svg'

const HomeButton = ({history, stylesAlt}) => {
    return (<button className={[css(styles.homeButton)]} style={stylesAlt} onClick={() => history.push('/')}>
        <Image src={homeLogo} fluid/>
    </button>)
}

export default HomeButton
