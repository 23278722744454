import React from 'react'
import {Link} from 'react-router-dom'
import s from './styles'
import {css} from 'aphrodite/no-important'

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)


const renderItem = (item, history, resetSelectedAssortmentIndex) => {

    const styles = s(item)

    if (!item) return null

    return <React.Fragment>
        <Link to={`/${item.url}`} onClick={() => {
            resetSelectedAssortmentIndex()
        }}>
            <p className={[css(styles.link)]}
            >{capitalizeFirstLetter(item.name)}</p>
        </Link>
        {item.children.length > 0 &&
        <React.Fragment>
            <span>/</span>
            {renderItem(...item.children, history, resetSelectedAssortmentIndex)}
        </React.Fragment>
        }</React.Fragment>
}

const BreadCrumb = ({breadCrumb, history, resetSelectedAssortmentIndex}) => {

    return renderItem(...breadCrumb, history, resetSelectedAssortmentIndex)

}

export default BreadCrumb
